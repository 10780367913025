<template>
  <div class="flex flex-col">
    <div class="flex justify-between">
      <h1 class="text-theme-sidebr text-2xl font-poppins mb-1">{{ $t(locale.title) }}</h1>

      <div
          class="d-flex smallGap text-secondaryColor align-items-center"
          role="button"
          @click="$router.go(-1)"
      >
        <mdicon :width="25" :height="25" name="arrow-left-thick"/>
        <p class="mb-0" v-text="$t(locale.backTo)"/>
      </div>
    </div>

    <b-card class="mt-3">
      <ValidationObserver ref="form">
        <b-row>

          <b-col cols="12" lg="4" v-for="(data, i) in generalForm" :key="i">
            <b-form-group :label="$t(data.label)" v-if="data.disabled">
              <div v-if="data.type === 'select'">
                <v-select
                    :placeholder="$t(locale.label)"
                    :options="data.options"
                    v-model="data.value"
                    @option:selected="computeSelect($event, i)"
                    class="resource-selector d-inline-block w-full"
                />
              </div>
              <div v-else-if="data.tabs">
                <LittleTabs
                    :tabs="data.tabs"
                    buttonSize="sm"
                    containerStyle="justify-content-start"
                    btnClass="tabWrapperSmall2"
                    v-model="data.value"
                    :defaultValue="true"
                />
              </div>
              <div v-else-if="data.mode === 'fee'">
                <v-select class="w-full" label="name" v-model="data.value" :filterable="false" :options="feesResults"
                          @search="searchFees">
                  <template slot="no-options">
                    Sök...
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }}
                    </div>
                  </template>
                </v-select>
              </div>
              <div v-else-if="data.mode === 'category'">
                <v-select class="w-full" label="name" v-model="data.value" :filterable="false" :options="memberCategory"
                          @search="searchMemberCategory">
                  <template slot="no-options">
                    Sök...
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }}
                    </div>
                  </template>
                </v-select>
              </div>
              <div v-else>
                <b-form-input
                    :placeholder="data.sub ? $t(data.sub) : $t(data.label)"
                    v-model="data.value"

                />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="flex justify-end mt-2">
              <b-button
                  :disabled="processing"
                  variant="success"
                  @click="createMember"
                  v-text="processing ? $t('Message.loading') : 'Create'"
              />
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import {createMemberMixin} from './createFormMixin'
import PAGES from "@/services/helpers/constant";
import {mapGetters} from "vuex";

export default {
  name: 'Member',
  mixins: [createMemberMixin],
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    CustomNoHeaderModalVue
  },
  data() {
    return {
      config: useAppConfig(),
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['golf_related']['members']))},
      items: {results: []},
      openUpdateCategory: false,
      selectedMembers: [],
      processing: false,
      openAddModal: false,
      selectedData: {},
      feeType: [],
      emptyCate: '',
      feesResults: [],
      memberCategory: [],
      queryParams: {
        limit: 30,
      },
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      locale: {
        title: 'createMemberCard.title',
        addSegment: 'CustomersCard.addSegment',
        edit: 'edit',
        backTo: 'golfRelatedCard.detail.backOverview',
      },
    }
  },
  computed: {
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    features() {
      if (this.getLang == '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.getLang == 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: "appConfig/getLocale"
    })
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    // this.getUserSubscriptions()
    this.disabledFields()
  },
  methods: {
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        hidden_object['hidden_fields']['golf_related']['memebrs'].map(item => {
          this.hidden_fields[item] = false
        })
        this.generalForm.map(item => {
          if (Object.keys(this.hidden_fields).includes(item.fieldValue) === true) {
            item.disabled = this.hidden_fields[item.fieldValue]
          } else {
            item.disabled = true
          }
        })

      } catch (e) {
      }
      this.fetchFees()
      this.fetchMemberCategory()
    },
    computeSelect(item, index) {
      this.generalForm[index].value = item.value
    },
    searchFees(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteFees(loading, search, this);
      }
    },
    searchRemoteFees: debounce(function (loading, search, vm) {
      this.$useJwt.getFeeRules({URL: '?search=' + escape(search), method: 'get'})
          .then(res => {
            this.feesResults = res.data.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    fetchFees: function () {
      this.$useJwt.getFeeRules({URL: '', method: 'get'})
          .then(res => {
            this.feesResults = res.data.data.results
            loading(false);
          })
          .catch(err => {
          })
    },
    searchMemberCategory(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteMemberCategory(loading, search, this);
      }
    },
    searchRemoteMemberCategory: debounce(function (loading, search, vm) {
      this.$useJwt.getMembersCategory({URL: '?search=' + escape(search), method: 'get'})
          .then(res => {
            this.memberCategory = res.data.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    fetchMemberCategory: function () {
      this.$useJwt.getMembersCategory({URL: '', method: 'get'})
          .then(res => {
            this.memberCategory = res.data.data.results
          })
          .catch(err => {
          })
    },
    createMember() {
      this.$refs.form.validate().then(res => {
        if (res) {
          this.processing = true
          const payload = {accounting_number: {}, player_category: {}}
          this.generalForm.map(el => {
            if (el.fieldValue === 'code') {
              payload.player_category[el.fieldValue] = el.value
            } else if (el.fieldValue === 'c_active') {
              payload.accounting_number['active'] = el.value
            } else if (el.fieldValue === 'customer_number') {
              payload.accounting_number[el.fieldValue] = el.value
            } else {
              payload[el.fieldValue] = el.value
            }
          })
          let requiredField = ['first_name', 'last_name', 'golf_id', 'country']
          for (let j = 0; j < requiredField.length; j++) {

            if (payload[requiredField[j]] === '' || payload[requiredField[j]] === null || payload[requiredField[j]] === undefined) {
              this.processing = false
              this.popupMsg(
                  this.$t('Message.Failed'),
                  `${requiredField[j].replace('_', '')} ${this.$t('isRequired')}`,
                  'AlertTriangleIcon',
                  'danger'
              );
              return false
            }
          }
          let data_ = {}
          for (const [key, value] of Object.entries(payload)) {
            if (value !== '' && value !== null && value !== undefined) {
              if (key === 'player_category') {
                if (payload.player_category.code) {
                  data_[key] = value
                }
              } else {
                data_[key] = value
              }

            }
          }
          this.$useJwt.getMembers({URL: '', method: 'post'}, data_)
              .then(res => {
                this.processing = false
                this.clearFormField()
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.message],
                    'CheckIcon',
                    'success'
                );
              })
              .catch(err => {
                this.processing = false
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data[this.message],
                    'AlertTriangleIcon',
                    'danger'
                );
              })
        }
      })
    },
    clearFormField() {
      this.generalForm.map((el, i) => {
        if (!el.tabs) {
          this.generalForm[i].value = ''
        }
      })
    },
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>