import { getCountries } from '@/views/newViews/accountingData/constants/countries';

export const createMemberMixin = {
    data() {
        return {
            generalForm: [
                {
                    label: 'createMemberCard.form.firstname',
                    value: '',
                    rules: 'required',
                    fieldValue : 'first_name',
                    disabled:true,
                },
                {
                    label : 'createMemberCard.form.lastname',
                    value: '',
                    rules: 'required',
                    fieldValue : 'last_name',
                    disabled:true,
                },
                {
                    disabled:true,
                    label : 'createMemberCard.form.email',
                    value: '',
                    rules: '',
                    fieldValue : 'email'
                },
                {
                    disabled:true,
                    label : 'createMemberCard.form.age',
                    value: '',
                    rules: '',
                    fieldValue : 'age'
                },
                {
                    disabled:true,
                    label : 'createMemberCard.form.active',
                    value: true,
                    rules: '',
                    tabs : [
                        {
                            label: 'CustomersCardEdit.yes',
                            active: true,
                            value: true,
                        },
                        {
                            label: 'CustomersCardEdit.no',
                            active: false,
                            value: false,
                        },
                    ],
                    fieldValue : 'active'
                },
                {
                    disabled:true,
                    label : 'createMemberCard.form.country',
                    type : 'select',
                    options : [...getCountries()],
                    value: '',
                    rules: 'required',
                    fieldValue : 'country'
                },
                {
                    disabled:true,
                    label : 'createMemberCard.form.sex',
                    value: 'M',
                    rules: '',
                    tabs : [
                        {
                            label: 'createMemberCard.form.male',
                            active: true,
                            value: 'M',
                        },
                        {
                            label: 'createMemberCard.form.female',
                            active: false,
                            value: 'F',
                        },
                    ],
                    fieldValue : 'sex'
                },
                {
                    disabled:true,
                    label : 'createMemberCard.form.homeAddress',
                    value: '',
                    rules: '',
                    fieldValue : 'home_street_address'
                },
                {
                    disabled:true,
                    label : 'createMemberCard.form.postAddress',
                    value: '',
                    rules: '',
                    fieldValue : 'post_street_address'
                },
                {
                    disabled:true,
                    label : 'createMemberCard.form.homePhoneNumber',
                    value: '',
                    rules: '',
                    fieldValue : 'home_phone_number'
                },
                {
                    disabled:true,
                    label : 'createMemberCard.form.mobilePhoneNumber',
                    value: '',
                    rules: '',
                    fieldValue : 'mobile_phone_number'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.workMobile',
                    value: '',
                    rules: '',
                    fieldValue : 'work_mobile_phone_number'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.zipCode',
                    value: '',
                    rules: '',
                    fieldValue : 'zip_code'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.city',
                    value: '',
                    rules: '',
                    fieldValue : 'city'
                },
                {disabled:true,
                    label: 'createMemberCard.form.memberNumber',
                    value: '',
                    rules: '',
                    fieldValue : 'member_number'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.homeClub',
                    value: '',
                    rules: '',
                    fieldValue : 'home_club'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.golfID',
                    value: '',
                    rules: 'required',
                    fieldValue : 'golf_id'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.hcp',
                    value: '',
                    rules: '',
                    fieldValue : 'HCP'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.memberCategory',
                    options : [],
                    mode : 'category',
                    value: '',
                    rules: '',
                    fieldValue : 'member_category'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.feeRule',
                    mode : 'fee',
                    value: '',
                    rules: '',
                    fieldValue : 'fee_rule'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.playerCategory',
                    sub : 'createMemberCard.form.code',
                    value: '',
                    rules: '',
                    fieldValue : 'code'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.accountingNum',
                    sub : 'createMemberCard.form.customerNumber',
                    value: '',
                    rules: '',
                    fieldValue : 'customer_number'
                },
                {
                    disabled:true,
                    label: 'createMemberCard.form.customerActive',
                    value: true,
                    rules: '',
                    tabs : [
                        {
                            label: 'CustomersCardEdit.yes',
                            active: true,
                            value: true,
                        },
                        {
                            label: 'CustomersCardEdit.no',
                            active: false,
                            value: false,
                        },
                    ],
                    fieldValue : 'c_active'
                },
            ],
        };
    },
};

// firstname
// lastname
// email
// age
// active
// sex
// homeAddress
// postAddress
// homePhoneNumber
// mobilePhoneNumber
// workMobile
// country
// zipCode
// city
// memberNumber
// homeClub
// golfID
// hcp
// memberCategory
// feeRule
// playerCategory
// code
// accountingNum
// customerActive